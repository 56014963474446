import './CloudApplication.css'
import dataAnalytics from '../../Assets/dataAnalytic.jpg'
export const CloudApplication=()=>{
    return(
        <div>
        <div className="cloudContainer" >
              
                <div className="subMain">
                    <h1>Cloud Application Development Services</h1>
                    <h2 style={{color:'white'}}>Grow like a pro’s Cloud Capabilities Help Clients Develop and Deploy Cloud-Native Apps and Solutions</h2>
                 </div>
              
              
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Our cloud app development services can help optimize – time, budget & resources</h1>
                    <p className="text">We offer full-cycle development services to create applications from scratch by leveraging SaaS & PaaS models as well as modernizing them.</p>
                    <p className="text">As a cloud app development company, we cater to businesses of all sizes and across geographies. We’ve over two decades of experience offering end-to-end services from tech consulting to deployment, maintenance & support.

              Our team combines their strong technical knowledge with holistic strategies to support you with migrating your applications & workloads to virtualized environments or refactoring them for more efficient utilization of cloud-native services. We can develop custom cloud applications & even deploy them in public, private, or hybrid environments for you.

              We build SaaS products and cloud solutions from scratch by utilizing AWS or Azure using open-source cloud technologies and languages, like Python, .NET, Node.JS & Java.

              Custom Cloud Software Development As part of modern software development best practices, we utilize cloud microservice-based architectures to consolidate applications into independent components. This provides you with enhanced agility, performance, and scalability. And, to ensure your business is cloud-ready, we develop cloud-native apps using DevOps tools & approaches, including Kubernetes, Containerization, Docker, Gitlab CI/CD, and many others, to enable automated management across the public, private, and hybrid cloud. We serve customers from different industries like fintech, media & entertainment, travel & hospitality & more.</p>

                </div>
                <div className="img">
                    <img src={dataAnalytics} alt="" />
                </div>
            </div>

            
        </div>
    )
}