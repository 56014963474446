import './ProductEnginnering.css'
import dataAnalytics from '../../Assets/product-engineering.jpg'
export const ProductEngineering=()=>{
    return(
        <div>
        <div className="ProductEngineeringCont" >
              
                <div className="subMain">
                    <h1>Software Product Engineering Services</h1>
                    <h2 style={{color:'white'}}>Leveraging Leading Technologies to Build Next-Gen Software Products</h2>
                    <h3 style={{color:'white'}}>As a software product engineering company, we support the entire development cycle from the pre-lifecycle to the maturity phase.</h3>
                 </div>
              
              
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Trusted software product engineering company for ISVs, product startups, and enterprises</h1>
                    <p className="text" style={{fontWeight:"bold"}}>Being a renowned product engineering services company, we are well-versed at fine-tuning the fundamentals of software architecture development to ensure you get the right business solution</p>
                    <p className="text">At Rishabh Software, we bring together top digital engineering, design, and product development talent to build software solutions that transform immersive product experiences. Our software product engineering team has proven experience in helping businesses to keep up with rapidly evolving market demands. We use emerging technology stack (from Cloud, IoT, AI, ML, AR, VR, and more) to create an impactful product with a delightful experience.

Our expert software engineers have helped all shapes and sizes of businesses to unlock multiple business opportunities by leveraging our flexible engagement models. We take care of your project requirements (from resource & technology infrastructure) across the various product development stages. The development of impactful product engineering solutions, continuous product innovation, product modernization, and product implementation make us a renowned software product engineering company.</p>

                </div>
                <div className="img">
                    <img src={dataAnalytics} alt="" />
                </div>
            </div>

            
        </div>
    )
}