import './MobileAppDevelopment.css'
import quality3 from '../../Assets/computerPhone.jpg'
export const MobileAppDevelopment=()=>{
    return(
        <div>
        <div className="MobileAppDevelopment" >
              
                <div className="subMain">
                    <h1>Data Analytics Services</h1>
                    <h2 style={{color:'white'}}>We Help Businesses Build Intelligent Data-Driven Solutions</h2>
                    <h3 style={{color:'white'}}>We provide data analytics & business intelligence services to help you discover new opportunities, hidden threats, identify core audience & their needs.</h3>
                 </div>
              
              
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Supporting you to transform data into actionable analytics</h1>
                    <p className="text" style={{fontWeight:"bold"}}>Our data and analytics services can help fast-track decision-making with data discovery, management, visualization & analysis.</p>
                    <p className="text">We’re a full-service provider experienced in data management, migration & reporting. Our team offers business analytics solutions tailored to your business needs.

We apply proprietary big data frameworks combined with popular open-source technologies like Apache Hadoop, Spark, Python & Kafka. Our team develops machine learning (ML) and deep learning algorithms to deliver a comprehensive toolset for storing, processing and analyzing large quantities of data. We offer cloud platform analytics based on our strategic partnerships with AWS & Azure to provide solutions across the data lifecycle.

As a data analytics services company, we can help you set up all the required elements of a digital data ecosystem – from the data lake, data warehouses, and OLAP (online analytical processing) to reporting, dashboarding & more. Our experienced team uses ETL & ELT techniques to transform complex corporate data from disparate sources into actionable information. with our data warehousing services, we support you to store this data for conducting further analysis & optimization.

Our Microsoft Gold Data Analytics competency can benefit your business with easy access to the Microsoft ecosystem. This would include tools, programs & on-demand tech support to deliver & deploy your analytics projects.</p>

                </div>
                <div className="img">
                    <img src={quality3} alt="" />
                </div>
            </div>

            
        </div>
    )
}