import './App.css';
import { Footer } from './Components/Footer';
import { Home } from './Components/Home';
import { Navbar } from './Components/Navbar';
import AllRoutes from './Routes/AllRoutes';

function App() {
  return (
    <div>
      <Navbar/>
    <AllRoutes/>
    {/* <Home/> */}
      <Footer/>
    </div>
  );
}

export default App;
