import React from 'react'
import {Route,Routes} from "react-router-dom"
import { Home } from '../Components/Home'
import { DigitalExperience } from '../Services/Digital-Experience/DigitalExperience'
import { CloudApplication } from '../Services/Cloud-Application/CloudApplication'
import { EnterprisesSoftware } from '../Services/Enterprise-Software/EnterprisesSoftware'
import { DataAnalytics } from '../Services/Data-Analytics/DataAnalytics'
import { ProductEngineering } from '../Services/Product-Engineering/ProductEnginnering'
import { MobileAppDevelopment } from '../Services/Mobile-App-Development/MobileAppDevelopment'
import { QualityControl } from '../Services/Quality-Control/QualityControl'
// import { DataAnalytics } from '../Services/Data-Analytics/DataAnalytics'

export default function AllRoutes() {
    return (
        <div>
<Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/digitalExperience' element={<DigitalExperience/>}/>
    <Route path='/cloudApplication' element={<CloudApplication/>}/>
    <Route path='/enterprisesSoftware' element={<EnterprisesSoftware/>}/>
    <Route path='/productEngineering' element={<ProductEngineering/>}/>
    <Route path='/mobileAppDevelopment' element={<MobileAppDevelopment/>}/>
    < Route path='/qualityControl' element={<QualityControl/>}/> 

    < Route path='/dataAnalytics' element={<DataAnalytics/>}/> 
 

</Routes>
        </div>
    )
}