import './DigitalExperience.css'
import dataAnalytics from '../../Assets/back.jpg'
export const DigitalExperience=()=>{
    return(
        <div>
           <div className="main">
              
                <div className="subMain">
                    <h1>Build a Brand That Stands Out in Today's Digital World</h1>
                    <h2 style={{color:'white'}}>We blend our tech expertise and visionary mindset to help you delight customers across touchpoints</h2>
                 
               </div>
              
              
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Delivering digital customer experiences that help re-ignite growth</h1>
                    <p className="text">We are a part creative, part strategic and part technology powerhouse, who help unlock personalization opportunities with a customer-first approach – always. Our teams help organizations scale the digital maturity curve faster. At Rishabh, we support you with either digitalizing specific business processes or a complete digital overhaul of the IT systems. With teams skilled in various technologies, we can help with web and mobile product engineering, re-engineering legacy applications, building AI-powered solutions & much more.

Our team leverages leading open-source web frameworks such as Drupal and WordPress along with front-end technologies including React, React Native, Angular, Flutter, and more to create a seamless experience. They have a stronghold on front-end architecture and design, UI/UX development.</p>
                   
                </div>
                <div className="img">
                    <img src={dataAnalytics} style={{height:"450px"}} alt="" />
                </div>
            </div>

            
        </div>
    )
}