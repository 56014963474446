import React from "react";
import './AllStyle.css';

import dataAnalytics from '../Assets/dataAnalytic.jpg'
import cloud from '../Assets/cloud.jpg';
import productEnineering from '../Assets/product-engineering.jpg'
import productEnterprise from '../Assets/product-Enterprise.jpg'
import { Link } from "react-router-dom";

export const Home=()=>{
    return(
        <div>
            <div className="container">
                {/* <Navbar/> */}
                <div className="sub-cont1">
                    <h1>Empowering Businesses with Advanced Cloud Solutions</h1>
                    <h2 style={{color:'white'}}>Transforming Enterprises through Innovative Software Services</h2>

                    {/* <button className="sub-btn">Scroll down >>> </button> */}
               </div>
                {/* <div className="sub-cont2">
                    <img src="https://ntwist.com/wp-content/uploads/2023/01/home-image-icons-v6.png" alt="" />
                </div> */}
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Data Analytics Services</h1>
                    <p className="text">Our data analytics & business intelligence services help you build data-driven solutions. We focus on data discovery, data management, data visualization & analysis to support you with discovering new opportunities while identifying hidden threats, core audience & their needs.</p>
                    <Link to='/dataAnalytics'><button className="btn">Read More</button></Link>
                </div>
                <div className="img">
                    <img src={dataAnalytics} alt="" />
                </div>
            </div>
            <div className="cont-parent">
                <div className="img2">
                    <img src={cloud} alt="" />
                </div>
                <div className="cont2">
                    <h1 className="title2">Cloud Application</h1>
                    <p className="text2">Our cloud capabilities help you develop and deploy cloud-native apps and solutions. As the certified partner for Microsoft Azure and AWS (Amazon Web Services), we offer app development & modernization along with consultation, managed services & migration.</p> 
                    {/* <p className="text2">With the increasing relevance of carbon accounting and emissions tracking, NTWIST offers a suite of tools to increase visibility into environmental performance and help processing plants track, manage, optimize, and report key metrics.</p> */}
                    <Link to="/cloudApplication"><button className="btn2">Read More</button></Link>
                </div>
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Product Engineering</h1>
                    <p className="text">Software is eating the world.” We all know this is happening at an incredible pace. As you build products to help transform your customers’ way of working, we have your back. We will be there with you to design, develop, test, and ship the products with the latest technologies within the core. As tech strategists, we will also help you address the shifts in your business environment through re-engineered solutions for the modern age. Work with us to experience our approach to Products!</p>
                    <Link to="productEngineering"><button className="btn">Read More</button></Link>
                </div>
                <div className="img">
                    <img src={productEnineering} alt="" />
                </div>
            </div>
            <div className="cont-parent">
                <div className="img2">
                    <img src={productEnterprise} alt="" />
                </div>
                <div className="cont2">
                    <h1 className="title2">Enterprise Software</h1>
                    <p className="text2">We take the driver’s seat to develop enterprise applications that enable you to take your business the extra mile. Our teams diligently work to create innovative solutions designed uniquely for you. We run it like our own - through strategic collaboration and an extensive consulting approach. As your software development partner, we leverage emerging technologies that ensure your application is future-ready and scalable. </p> 
                    {/* <p className="text2">NTWIST helps Oil & Gas customers to analyze historical data, identify opportunities for process control improvements, and reduce variability in any part of the process, thus providing enhanced reliability and safety.</p> */}
                    <Link to='/EnterprisesSoftware'><button className="btn2">Read More</button></Link>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
        
    )
    
}