import './EnterprisesSoftware.css'
import dataAnalytics from '../../Assets/dataAnalytics2.jpg';
export const EnterprisesSoftware=()=>{
    return(
        <div>
           <div className="EnterprisesSoftware">
              
                <div className="subMain">
                    <h1>Enterprise Software Development Services</h1>
                    <h2 style={{color:'white'}}>Helping you build enterprise solutions for a connected software ecosystem</h2>
                 </div>
              
              
             </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Custom Enterprise software development services to address your varied needs</h1>
                    <h3>With over 20 years of experience in developing web & mobile enterprise solutions, we support your digital transformation initiatives of any scale.</h3>
                    <p className="text">Our team utilizes popular technologies to integrate your current applications and develop tailored-fit enterprise solutions. We provide technical consultation based on your existing IT infrastructure & technology requirements.

                       We have the competency & experience in the Internet of Things, Data Analytics, Cloud Computing, Low Code/No Code & more to help you design future-ready solutions. Our enterprise application implementation process covers – business analysis, project planning, application architecture design, UI/UX design & application development along with support for QA & testing. With a specialized focus on legacy enterprise software modernization, we help you to re-engineer applications with recoding, containerization, and cloud migration. Further, we also support you with enterprise app integration.</p>
                   
                </div>
                <div className="img">
                    <img src={dataAnalytics} alt="" />
                </div>
            </div>

            
        </div>
    )
}