import './QualityControl.css'
import dataAnalytics from '../../Assets/quality-Control-Eng.jpg'
export const QualityControl=()=>{
    return(
        <div>
        <div className="QualityControlCont" >
              
                <div className="subMain">
                    <h1>Foundations of Quality Control and Engineering at GrowLikePro</h1>
                    <h2 style={{color:'white'}}> we believe that quality control begins with a thorough understanding of our clients' needs and requirements.</h2>
                 </div>
              
              
            </div>
            <div className="cont-parent">
                <div className="cont">
                    <h1 className="title">Comprehensive Quality Control Process at GrowLikePro</h1>
                    <p className="text" style={{fontWeight:"bold"}}>Quality control and engineering at GrowLikePro, a leading software-based company, are fundamental pillars that drive our commitment to excellence in product development. </p>
                    <p className="text">At GrowLikePro, we believe that quality control begins with a thorough understanding of our clients' needs and requirements. We work closely with our clients to gather detailed specifications and expectations, which serve as the foundation for our development process. This initial phase is crucial as it sets the stage for the entire project and ensures that we are aligned with our clients' goals.

Once the requirements are defined, our team of skilled engineers and developers utilizes industry best practices and cutting-edge technologies to design and develop software solutions that are innovative, robust, and scalable. Throughout the development process, we adhere to strict quality control measures, including code reviews, automated testing, and performance analysis, to identify and rectify any issues or defects promptly.

In addition to our internal quality control processes, we also place a strong emphasis on user feedback and testing. We conduct rigorous usability testing and gather feedback from users to ensure that our products are intuitive, easy to use, and meet the needs of our target audience. This user-centric approach allows us to continuously improve our products and deliver exceptional user experiences.

</p>

                </div>
                <div className="img">
                    <img src={dataAnalytics} alt="" />
                </div>
            </div>

            
        </div>
    )
}