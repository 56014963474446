import React, { useEffect, useState } from "react";
import './AllStyle.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Link} from "react-router-dom"
import growDark from "../Assets/sales_10139611.png"

export const Navbar=()=>{
    const [scrolling, setScrolling] = useState(false);
    const [isIndustriesOpen, setIndustriesOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleIndustries = () => {
    setIndustriesOpen(!isIndustriesOpen);
  };
  console.log(scrolling);
    return(
        <div id="cont">
        <div className= {scrolling?"parent_active":"parent"}>
            <div className="logo" >
                <img  src={scrolling? growDark:growDark} alt="err" />
                <h2 style={{color:'rgb(0,123,190)', paddingTop:'6px'}}>GROW LIKE PRO</h2>
            </div>
            <div className={scrolling?"side1_active":"side1"}>
            
              <Link to="/" style={{textDecoration: 'none'}}><h3 >Home</h3></Link>
           
              
                <h3 
                 className={`navbar-item   ${isIndustriesOpen ? 'active' : ''}`}
                 onMouseEnter={toggleIndustries}
                 onMouseLeave={toggleIndustries}
                >
                  <span>Services </span><ArrowDropDownIcon  />
                    {isIndustriesOpen && (
            <div className="dropdown">
              <ul className="dropdown-menu">
                <li className="kdjshkhfd">
                  
                  <Link to="dataAnalytics" style={{textDecoration: 'none'}}><p  >Data Analytics</p></Link>
                
                 
                </li>
                <li className="kdjshkhfd">
                <Link to="/productEngineering">   <p >Product Engineering</p></Link>

                  
                
                 
                </li>
                <li>
                  <Link to="enterprisesSoftware">  <p >Enterprise Software</p></Link>
                 
                </li>
               
                <li>
                  
                  <Link to="/mobileAppDevelopment"><p >Mobile App Development</p></Link>
                </li>
                <li>
                 <Link to="/digitalExperience" > <p >Digital Experience</p></Link>
                </li>
                <li>
                 <Link to="/qualityControl" > <p >Quality Control & Engineering</p></Link>
                </li>
                
              </ul>
            </div>
          )}
                </h3>
           <Link to="/cloudApplication" style={{textDecoration: 'none'}}>     <h3 >Cloud Application</h3></Link>
                <h3>Blog</h3>
                <h3>Contact Us</h3>
            </div>
        </div>
    </div>
    )
      
    
}
