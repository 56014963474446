import React from "react";
import growDark from "../Assets/sales_10139611.png"


export const Footer=()=>{
    return(
        <div className="foot-cont">
            <div className="ft-img">
            <img  src={growDark} alt="err" />
                <h2 style={{color:'rgb(0,123,190)', paddingTop:'14px'}}>GROW LIKE PRO</h2>            </div>
            <div className="ft-title">
                <h3>Home</h3>
                <h3>Contact Us</h3>
                <h3>Privacy Policy</h3>
                <h3>Sitemap</h3>
            </div>
            <div className="ft-text">
                <p>9650 20 Ave NW, Edmonton, AB, T6N 1G1, Canada</p>
            </div>
           <div className="ft-text">
           <p>© 2022. Grow Like Pro Inc.</p>
           </div>
    </div>
    )
}